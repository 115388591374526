.body {
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow:hidden;
}

.translation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-grid {
  display: flex;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
}

.image-container {
  flex: 1; /* Each container takes 1/3 of the width */
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.image {
  width: 100%;
  height: 100%;
  padding: 4vh;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.image-container:hover .image {
  transform: scale(1.2); /* Scale the hovered image */
}

.image-container:not(:hover) .image {
  filter: brightness(50%); /* Darken non-hovered images */
}

.image-container:hover {
  flex: 1.2; /* Increase flex to make the hovered container larger */
  transition: flex 0.3s ease;
}

.image-container:not(:hover) {
  flex: 0.9; /* Slightly reduce flex for non-hovered containers */
}


.image-reveal {
  display: flex;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  position: relative;
}

.image-container {
  flex: 1; /* Each container takes half the width */
  overflow: hidden;
  transition: filter 0.3s ease;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.image-container:hover .image {
  transform: scale(1.1); /* Slight scaling on hover */
}

.image-container.blurred .image {
  filter: brightness(40%); /* Darken and blur the background images */
}

.image-container.second:hover .image {
  filter: brightness(40%);
}

/* Third hidden image settings */
.third-image-container {
  position: absolute;
  top: 0;
  left: 25%; /* Centered by occupying the middle 50% of the screen */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: 1; /* Bring the third image to the front */
}

.foreground-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2); /* Larger scale for foreground */
}

.animated-image-grid {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.image-container3 {
  flex: 1;
  overflow: hidden;
  position: relative;
  transition: filter 0.3s ease;
}

.image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-container3.active .image3 {
  transform: scale(1.2); /* Scale up by 20% */
}

.image-container3.fade .image3 {
  filter: brightness(30%); /* Darken the image */
}

/* Animated Text Overlay */
.animated-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 0.2em;
  animation: fadeInOut 30s infinite;
}

.animated-char {
  font-size: 2em;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  animation: characterFade 3s infinite;
}

/* Animation for each character with delay */
.animated-char:nth-child(1) { animation-delay: 0s; }
.animated-char:nth-child(2) { animation-delay: 0.2s; }
.animated-char:nth-child(3) { animation-delay: 0.4s; }
.animated-char:nth-child(4) { animation-delay: 0.6s; }
.animated-char:nth-child(5) { animation-delay: 0.8s; }
.animated-char:nth-child(6) { animation-delay: 1s; }
.animated-char:nth-child(7) { animation-delay: 1.2s; }
.animated-char:nth-child(8) { animation-delay: 1.4s; }
.animated-char:nth-child(9) { animation-delay: 1.6s; }

/* Keyframes */
@keyframes characterFade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.centerstage {
display: flex;
flex-direction: column;
padding: 1vh;
margin: 1vh 1vh 1vh 1vh;
}

.centerline {
  display: flex;
  flex-direction: column;
  padding: 0.2vh;
  margin: 0.2vh;
  height: 40vh;
}

.touchscreen {  
font-size: medium;
font-family: 'mkMyth';
padding: 1vh;
margin: 1vh;
color: white;
opacity: 0.9;
background-color: black;
border: 1vh double whitesmoke;
cursor: pointer;
width: 98%;
}
.touchscreen:active {
  color: whitesmoke;
  border: 1vh double whitesmoke;
  opacity: 0.65;
  background-color: goldenrod;
}
.navigator {
  height: 4vh;
  width: 4vh;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .animated-image-grid {
    flex-direction: column; /* Stack images vertically */
    height: auto;
  }

  .centerline {    
  height: 50vh;
  }

  .image-container3 {
    width: 100vw; /* Full width for each row */
    height: 33vh; /* Adjust height as needed for three rows */
  }

  .image3 {
    width: 100vw;
    height: 33vh;
    object-fit: cover;
    object-position: top right;
  }

  /* Adjust animated text overlay for mobile */
  .animated-text-overlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 0.1em;
  }

  .animated-char {
    font-size: 1.5em; /* Slightly smaller font size for mobile */
  }
}



@font-face {
  font-family: 'Harlow';
  src: url(./fonts/harlowsi.ttf) format('truetype');
}

@font-face {
  font-family: 'mkMyth';
  src: url(./fonts/mkmyth.ttf) format('truetype');
}

@font-face {
  font-family: 'NexaHeavy';
  src: url(./fonts/Nexa-Heavy.ttf) format('truetype');
}

@font-face {  
  font-family: 'RealVirtue';
  src: url(./fonts/REALVIRTUE.ttf) format('truetype');
}

@font-face {  
  font-family: 'EvilEmpire';
  src: url(./fonts/EvilEmpire.ttf) format('truetype');
}

/* Set the style for the scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

/* Set the style for the thumb of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
  border: 1px gold solid;
  cursor: grab;
}

/* Sound styles */
.swdesktop {
  position: fixed;
  display: flex;
  top: 45vh; 
  width: 7.5vw;
  flex-direction: column;
  height: 28vh;
}

.swdesktop2 {
  position: fixed;
  display: flex;
  top: 47vh; 
  width: 6vw;
  flex-direction: column;
  height: 28vh;
}

.swmobile {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 8vh;
  margin-left: 1vw;
  align-content: center;
  justify-content: space-evenly;
}

.soundicon {
  border-radius: 250px;
  margin-left: 0.5vw;
  margin-top: 1.5vh;
  cursor: pointer;
  opacity: 0.5;
}

.soundicon:hover {
  opacity: 0.95;
}

.sheight{
  height: 3vw;
  width: 3vw;
}

.sheight:hover {
  margin-left: 4vh;
}

.sheightmb{
  height: 5vw;
  width: 5vw;
}

.sheightmb:hover {
  margin-left: 2vh;
}

.theight{
  height: 5.5vw;
  width: 5.5vw;
}

.theightmb{
  height: 6.5vw;
}

.soundwidth{
  position: fixed;
  display: none;
  max-width: 10vw;
  max-height: 10vh;
  /* border: 1px white solid; */
}

.tutorial {
  border: double 0.25vw;
  padding: 0.25vh;
  border-radius: 1vh;
  font-family: 'mkMyth';
  font-size: 2vh;
}
